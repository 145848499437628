<template>
  <div class="list-state-group">
    <el-radio-group v-model="curVal" size="mini" @change="changeSize">
      <el-radio-button v-for="({ label, value }, index) in list" :label="value" :key="index">{{
        label
      }}</el-radio-button>
    </el-radio-group>
  </div>
</template>

<script>
import { commonEheaderMixin } from '@/mixins'
import { list } from './const'
import { getSearchValueMixin } from '@/mixins'

export default {
  mixins: [commonEheaderMixin, getSearchValueMixin()],
  props: {
    query: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      curVal: this.query.orderStatus
    }
  },
  computed: {
    list() {
      return list.filter((item) => item.permission)
    },
    childPermission({ curItem: { childPermission } }) {
      return childPermission
    }
  },
  watch: {
    list: {
      handler(n) {
        if (n.length) {
          let value = n[0].value
          this.curVal = value
          this.changeSize()
        }
      },
      immediate: true,
      deep: true
    },
    childPermission: {
      handler(n) {
        if (!n) return
        this.$emit('updateCheck', n)
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    changeSize() {
      this.getSearchValue(this.query)

      this.sup_this.page = 1
      this.toQuery()

      this.$emit('takeStatus', this.query.orderStatus)
    }
  }
}
</script>

<style lang="scss" scoped></style>
