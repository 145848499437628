import { checkPermission } from '@/utils/permission'
import {
  WAIT_RELATION,
  IN_DISPATCH,
  IN_PRODUCTION,
  WAIT_PAIT,
  WAIT_PAID_FREIGHT,
  PAID,
  PENDING,
  AFTER_COMPLETE,
  SUBACCOUNT_REVIEW,
  WAIT_UPLOAD_EXPRESS,
  UPLOADED_PACKING,
  DELIVER,
  CANCEL,
  WAIT_UPLOAD_PACKING,
  CUTOFF_STATUS_CUTOFF,
  EXPORT_STATUS_NO,
  EXPORT_STATUS_YES
} from '@/utils/constant'

export const list = [
  {
    label: '全部订单',
    value: '',
    permission: checkPermission(['externaladmin:orderManage:orderFbaList:all']),
    childPermission: {
      search: checkPermission(['externaladmin:orderManage:orderFbaList:all:search']),
      detail: checkPermission(['externaladmin:orderManage:orderFbaList:all:detail']),
      // downloadInvoice: checkPermission(['externaladmin:orderManage:orderFbaList:all:downloadInvoice'])
    }
  },
  {
    label: '待关联',
    value: WAIT_RELATION,
    searchValue: {
      orderStatus: WAIT_RELATION
    },
    permission: checkPermission(['externaladmin:orderManage:orderFbaList:1']),
    childPermission: {
      search: checkPermission(['externaladmin:orderManage:orderFbaList:1:search']),
      detail: checkPermission(['externaladmin:orderManage:orderFbaList:1:detail']),
      // downloadInvoice: checkPermission(['externaladmin:orderManage:orderFbaList:1:downloadInvoice'])
    }
  },
  {
    label: '排单中',
    value: IN_DISPATCH,
    searchValue: {
      orderStatus: IN_DISPATCH
    },
    permission: checkPermission(['externaladmin:orderManage:orderFbaList:3']),
    childPermission: {
      search: checkPermission(['externaladmin:orderManage:orderFbaList:3:search']),
      detail: checkPermission(['externaladmin:orderManage:orderFbaList:3:detail']),
      // downloadInvoice: checkPermission(['externaladmin:orderManage:orderFbaList:3:downloadInvoice'])
    }
  },
  {
    label: '生产中-已截单',
    value: 'IN_PRODUCTION_CUTOFF',
    searchValue: {
      orderStatus: IN_PRODUCTION,
      cutoffStatus: CUTOFF_STATUS_CUTOFF
    },
    permission: checkPermission(['externaladmin:orderManage:orderFbaList:4']),
    childPermission: {
      search: checkPermission(['externaladmin:orderManage:orderFbaList:4:search']),
      detail: checkPermission(['externaladmin:orderManage:orderFbaList:4:detail']),
      // downloadInvoice: checkPermission(['externaladmin:orderManage:orderFbaList:4:downloadInvoice'])
    }
  },
  {
    label: '生产中-未导出',
    value: 'IN_PRODUCTION_NOT_EXPORT',
    searchValue: {
      orderStatus: IN_PRODUCTION,
      exportStatus: EXPORT_STATUS_NO
    },
    permission: checkPermission(['externaladmin:orderManage:orderFbaList:5']),
    childPermission: {
      search: checkPermission(['externaladmin:orderManage:orderFbaList:5:search']),
      detail: checkPermission(['externaladmin:orderManage:orderFbaList:5:detail']),
      // downloadInvoice: checkPermission(['externaladmin:orderManage:orderFbaList:5:downloadInvoice'])
    }
  },
  {
    label: '待分箱',
    value: 'IN_PRODUCTION_STAY_POINTS',
    searchValue: {
      orderStatus: IN_PRODUCTION,
      exportStatus: EXPORT_STATUS_YES
    },
    permission: checkPermission(['externaladmin:orderManage:orderFbaList:6']),
    childPermission: {
      search: checkPermission(['externaladmin:orderManage:orderFbaList:6:search']),
      detail: checkPermission(['externaladmin:orderManage:orderFbaList:6:detail']),
      // downloadInvoice: checkPermission(['externaladmin:orderManage:orderFbaList:6:downloadInvoice'])
    }
  },
  {
    label: '待选择物流',
    value: WAIT_UPLOAD_EXPRESS,
    searchValue: {
      orderStatus: WAIT_UPLOAD_EXPRESS
    },
    permission: checkPermission(['externaladmin:orderManage:orderFbaList:7']),
    childPermission: {
      search: checkPermission(['externaladmin:orderManage:orderFbaList:7:search']),
      detail: checkPermission(['externaladmin:orderManage:orderFbaList:7:detail']),
      // downloadInvoice: checkPermission(['externaladmin:orderManage:orderFbaList:7:downloadInvoice'])
    }
  },
  {
    label: '待上传装箱单',
    value: WAIT_UPLOAD_PACKING,
    searchValue: {
      orderStatus: WAIT_UPLOAD_PACKING
    },
    permission: checkPermission(['externaladmin:orderManage:orderFbaList:8']),
    childPermission: {
      search: checkPermission(['externaladmin:orderManage:orderFbaList:8:search']),
      detail: checkPermission(['externaladmin:orderManage:orderFbaList:8:detail']),
      // downloadInvoice: checkPermission(['externaladmin:orderManage:orderFbaList:8:downloadInvoice'])
    }
  },
  {
    label: '已上传装箱单',
    value: UPLOADED_PACKING,
    searchValue: {
      orderStatus: UPLOADED_PACKING
    },
    permission: checkPermission(['externaladmin:orderManage:orderFbaList:9']),
    childPermission: {
      search: checkPermission(['externaladmin:orderManage:orderFbaList:9:search']),
      detail: checkPermission(['externaladmin:orderManage:orderFbaList:9:detail']),
      // downloadInvoice: checkPermission(['externaladmin:orderManage:orderFbaList:9:downloadInvoice'])
    }
  },
  {
    label: '已发货',
    value: DELIVER,
    searchValue: {
      orderStatus: DELIVER
    },
    permission: checkPermission(['externaladmin:orderManage:orderFbaList:11']),
    childPermission: {
      search: checkPermission(['externaladmin:orderManage:orderFbaList:11:search']),
      detail: checkPermission(['externaladmin:orderManage:orderFbaList:11:detail']),
      // downloadInvoice: checkPermission(['externaladmin:orderManage:orderFbaList:11:downloadInvoice'])
    }
  },
  {
    label: '已取消',
    value: CANCEL,
    searchValue: {
      orderStatus: CANCEL
    },
    permission: checkPermission(['externaladmin:orderManage:orderFbaList:12']),
    childPermission: {
      search: checkPermission(['externaladmin:orderManage:orderFbaList:12:search']),
      detail: checkPermission(['externaladmin:orderManage:orderFbaList:12:detail']),
      // downloadInvoice: checkPermission(['externaladmin:orderManage:orderFbaList:12:downloadInvoice'])
    }
  },
  {
    label: '待付货款',
    value: WAIT_PAIT,
    searchValue: {
      orderStatus: WAIT_PAIT
    },
    permission: checkPermission(['externaladmin:orderManage:orderFbaList:13']),
    childPermission: {
      search: checkPermission(['externaladmin:orderManage:orderFbaList:13:search']),
      detail: checkPermission(['externaladmin:orderManage:orderFbaList:13:detail'])
    }
  },
  {
    label: '子账号审核货款',
    value: PAID,
    searchValue: {
      orderStatus: PAID
    },
    permission: checkPermission(['externaladmin:orderManage:orderFbaList:14']),
    childPermission: {
      search: checkPermission(['externaladmin:orderManage:orderFbaList:14:search']),
      detail: checkPermission(['externaladmin:orderManage:orderFbaList:14:detail'])
    }
  },
  {
    label: '待付运费',
    value: WAIT_PAID_FREIGHT,
    searchValue: {
      orderStatus: WAIT_PAID_FREIGHT
    },
    permission: checkPermission(['externaladmin:orderManage:orderFbaList:15']),
    childPermission: {
      search: checkPermission(['externaladmin:orderManage:orderFbaList:15:search']),
      detail: checkPermission(['externaladmin:orderManage:orderFbaList:15:detail'])
    }
  },
  {
    label: '子账号审核运费',
    value: SUBACCOUNT_REVIEW,
    searchValue: {
      orderStatus: SUBACCOUNT_REVIEW
    },
    permission: checkPermission(['externaladmin:orderManage:orderFbaList:16']),
    childPermission: {
      search: checkPermission(['externaladmin:orderManage:orderFbaList:16:search']),
      detail: checkPermission(['externaladmin:orderManage:orderFbaList:16:detail'])
    }
  },
  {
    label: '售后待处理',
    value: PENDING,
    searchValue: {
      orderStatus: PENDING
    },
    permission: checkPermission(['externaladmin:orderManage:orderFbaList:17']),
    childPermission: {
      search: checkPermission(['externaladmin:orderManage:orderFbaList:17:search']),
      detail: checkPermission(['externaladmin:orderManage:orderFbaList:17:detail'])
    }
  },
  {
    label: '售后完成',
    value: AFTER_COMPLETE,
    searchValue: {
      orderStatus: AFTER_COMPLETE
    },
    permission: checkPermission(['externaladmin:orderManage:orderFbaList:18']),
    childPermission: {
      search: checkPermission(['externaladmin:orderManage:orderFbaList:18:search']),
      detail: checkPermission(['externaladmin:orderManage:orderFbaList:18:detail'])
    }
  }
]
